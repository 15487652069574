import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  ScriptableContext,
  TooltipItem,
  ChartEvent,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar, Scatter } from 'react-chartjs-2';
import { COLORS } from '../Misc/consts';
import { TBarChartData } from 'dippa-shared';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
  Tooltip
);


// @ts-ignore
Tooltip.positioners.cursor = function (_, coordinates) { return coordinates; };
ChartJS.defaults.font.family = "Outfit";
ChartJS.defaults.font.size = 11.5;


const CustomHoverPlugin = {
  id: "hoverSegment",
  beforeInit: (chart: ChartJS<"bar"> & { sharedParameter: { hoverValue: number | undefined } }) => {
    // Initialize a shared parameter
    chart.sharedParameter = { hoverValue: undefined };
  },
  //beforeDatasetsDraw
  beforeDatasetDraw: (
    chart: ChartJS<"bar"> & { sharedParameter: { hoverValue: number | undefined } },
    args: any,
    plugins: any
  ) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
      scales: { x, y }
    } = chart;
    //ctx.save();

    if (chart.sharedParameter.hoverValue === undefined) {
      //ctx.restore();
      return
    }
    ctx.fillStyle = "rgba(255, 255, 255, 0.1)"
    const barWidth = height / (y.max + 1) * 0.9 - 1;
    ctx.fillRect(
      left,
      y.getPixelForValue(Math.max(Math.min(chart.sharedParameter.hoverValue, y.max), 0)) - barWidth / 2,
      width,
      barWidth
    )
    //ctx.restore();
  },
  afterEvent: (
    chart: ChartJS<"bar"> & { sharedParameter: { hoverValue: number | undefined } },
    args: { inChartArea: boolean, event: ChartEvent, changed: boolean },
    plugins: any
  ) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
      scales: { x, y }
    } = chart;

    if (args.inChartArea && args.event.y !== null) {
      chart.sharedParameter.hoverValue = y.getValueForPixel(args.event.y);
    }
    else {
      chart.sharedParameter.hoverValue = undefined;
    }
    args.changed = true;
  }
}


const ConfidenceIntervalPlugin = {
  id: "confidenceIntervalPlugin",
  afterDatasetsDraw: (chart: ChartJS<"bar">) => {
    const ctx = chart.ctx;
    const strokeColor = "#ffffff";
    for (let i = 0; i < chart.data.datasets.length; i++) {
      const dataset = chart.data.datasets[i];
      // @ts-ignore
      if (!dataset?.confidenceIntervals) continue;
      const meta = chart.getDatasetMeta(i);
      // @ts-ignore
      dataset.confidenceIntervals.forEach((ci: [number, number] | [null, null], index) => {
        if (ci[0] === null) return;
        const yPos = meta.data[index].y;
        const xPosLeft = chart.scales.x.getPixelForValue(ci[0]);
        const xPosRight = chart.scales.x.getPixelForValue(ci[1]);

        // Horizontal line
        ctx.beginPath();
        ctx.moveTo(xPosLeft, yPos);
        ctx.lineTo(xPosRight, yPos);
        ctx.lineWidth = 2;
        ctx.fillStyle = strokeColor;
        ctx.strokeStyle = strokeColor;
        ctx.stroke();
        ctx.restore();
        ctx.save();

        // Left vertical line
        ctx.beginPath();
        ctx.moveTo(xPosLeft, yPos - 4);
        ctx.lineTo(xPosLeft, yPos + 4);
        ctx.lineWidth = 2;
        ctx.fillStyle = strokeColor;
        ctx.strokeStyle = strokeColor;
        ctx.stroke();
        ctx.restore();
        ctx.save();

        // Right vertical line
        ctx.beginPath();
        ctx.moveTo(xPosRight, yPos - 4);
        ctx.lineTo(xPosRight, yPos + 4);
        ctx.lineWidth = 2;
        ctx.fillStyle = strokeColor;
        ctx.strokeStyle = strokeColor;
        ctx.stroke();
        ctx.restore();
        ctx.save();
      });
    }
  }
};


export const HorizontalBarChart = ({
  data,
  fillColors
}: {
  data: TBarChartData,
  fillColors?: { [key: string]: string }
}) => {
  const options: React.ComponentProps<typeof Bar>["options"] = React.useMemo(() => ({
    indexAxis: 'y',
    elements: {
      bar: {
        borderRadius: 8,
        borderWidth: 1
      },
    },
    scales: {
      x: {
        suggestedMax: Math.max(...data.map(({ confRange }) => confRange?.[1]).filter(n => n) as Array<number>),
        grid: {
          color: COLORS.grays.chart_grid,
        },
        ticks: {
          color: COLORS.grays.chart_ticks
        }
      },
      y: {
        grid: {
          color: COLORS.grays.chart_grid,
        },
        ticks: {
          color: COLORS.grays.chart_ticks
        }
      },
    },
    plugins: {
      tooltip: {
        backgroundColor: "#393939",
        displayColors: false,
        mode: 'nearest',
        position: "cursor" as "nearest",
        caretSize: 0,
        axis: "y",
        padding: 10,
        intersect: false, // Tooltip will be shown even when not intersecting the element
        callbacks: {
          title: (tooltipItemArr: Array<TooltipItem<"bar">>) => {
            const index = tooltipItemArr?.[0]?.dataIndex;
            if (index === undefined || !data) return;
            return data[index].tooltip.title
          },
          label: (tooltipItem: TooltipItem<"bar">) => {
            const index = tooltipItem?.dataIndex;
            if (index === undefined || !data) return;
            return data[index].tooltip.label;
          }
        },
      },

    },
    animation: {
      duration: 650
    }
  }), [data])


  const getBackgroundColor = React.useCallback(
    (context: ScriptableContext<"bar">) => {
      if (fillColors) {
        return fillColors[data[context.dataIndex].name]
      }

      return COLORS.sitowise.graniitti
    }, [data])


  return (
    <Bar
      options={options}
      height={230}
      // @ts-ignore 
      plugins={[CustomHoverPlugin, ConfidenceIntervalPlugin]}
      data={{
        labels: data.map((v) => v.name),
        datasets: [
          {
            categoryPercentage: 1,
            barPercentage: 0.95,
            data: data.map((v) => v.val),
            hoverBackgroundColor: getBackgroundColor,
            backgroundColor: getBackgroundColor,
            // @ts-ignore
            confidenceIntervals: data.map((v) => {
              if (!v.confRange) return [null, null]
              return v.confRange
            }),
          }
        ],
      }} />
  )
}


export const ScatterChart = React.memo(function ScatterChart({ scatter }: {
  scatter: {
    points: Array<{ x: number | string, y: number | string }>,
    lines: Array<Array<{ x: number, y: number }>>
  }
}
) {
  const options: React.ComponentProps<typeof Scatter>["options"] = React.useMemo(() => ({
    scales: {
      x: {
        grid: {
          color: COLORS.grays.chart_grid,  // Set the grid color for the x-axis
        },
        ticks: {
          color: COLORS.grays.chart_ticks
        }
      },
      y: {
        beginAtZero: false,
        grid: {
          color: COLORS.grays.chart_grid,  // Set the grid color for the y-axis
        },
        ticks: {
          color: COLORS.grays.chart_ticks
        }
      }
    },
    plugins: {
      tooltip: {
        backgroundColor: "#393939",
        displayColors: false,
        mode: "index",
        //caretSize: 0,
        axis: "x",
        padding: 10,
        callbacks: {
          label: (tooltipItem: TooltipItem<"scatter">) => (
            [`x: ${tooltipItem.parsed.x}`, `y: ${tooltipItem.parsed.y}`]
          )
        },
      },
    },
    animation: false
  }), []);

  return (
    <Scatter
      height={230}
      options={options}
      data={{
        datasets: [
          // @ts-ignore
          ...scatter.lines.map((line, index) => ({ data: line, type: "line", fill: false, borderColor: COLORS.sitowise.graniitti, borderWidth: 1.5, pointRadius: 2 })),
          // @ts-ignore
          { data: scatter.points, backgroundColor: COLORS.grays.chart_scatter_points, pointRadius: 2.75, borderWidth: 0.5, borderColor: "#000000" }
        ],
      }} />
  );
})