import { formatBuildPropKey, formatBuildPropValue, TBuildingProperties } from "dippa-shared";
import React, { useContext, useEffect, useRef, useState } from "react";
import { COLORS } from "src/Misc/consts";
import { TooltipContext } from "src/Misc/Context";
import "./Tooltip.css"

const keys = [
  "rtunnus",
  "eluokka",
  //"tilavuus",
  "vvuosi",
  // "rluok",
  "lammonlahde"
] as const;


export const Tooltip = () => {
  const tooltip = useContext(TooltipContext);
  const tooltipRef = useRef(tooltip)
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = React.useCallback((event: any) => {
    setMousePosition({ x: event.clientX + 6, y: event.clientY - 6 });
  }, []);


  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);


  useEffect(() => {
    if (!tooltip) return;
    tooltipRef.current = tooltip;
  }, [tooltip])


  if (!tooltipRef.current) return null;
  const buildingProperties = tooltipRef.current.properties;

  return (
    <div
      className={`tooltip ${tooltip && mousePosition ? 'visible' : ''}`}
      style={{
        borderColor: COLORS.eluokat[buildingProperties.eluokka],  //"#175378",
        top: mousePosition.y,
        left: mousePosition.x
      }}
    >
      {keys.map((key, index) => {
        const val = formatBuildPropValue(buildingProperties[key], key);
        const color = key === "eluokka" && val ? COLORS.eluokat[val as TBuildingProperties["eluokka"]] : undefined;
        return (
          <div key={index}>
            <p>
              {formatBuildPropKey(key as keyof TBuildingProperties)}
            </p>
            <h2 style={{ color: color, fontSize: "13px", lineHeight: "13px" }}>
              {val}
              {/* {key === "rluok" ? val.split(" ")[0] : val}

              {key === "rluok" ? (
                <>
                  <br />
                  {val.split(" ").slice(1).join(" ")}
                </>
              ) : null} */}
            </h2>
          </div>
        )
      })}
    </div>
  )
}