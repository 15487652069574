import React from "react";
import { Button } from "@mui/material";
import "./CheckboxTitle.css"



export const CheckboxTitle = ({
  title,
  selectAll,
  deselectAll
}: {
  title: string,
  selectAll?: () => void,
  deselectAll?: () => void
}) => {

  return (
    <>
      <p>
        {title}
      </p>
      {selectAll && deselectAll ? (
        <div className="checkbox-title">
          <Button
            variant="text"
            onClick={selectAll}
            sx={{ fontSize: 11, height: 18, width: 88 }}
          >
            {"Valitse kaikki"}
          </Button>

          <Button
            variant="text"
            onClick={deselectAll}
            sx={{ fontSize: 11, height: 18, width: 88 }}
          >
            {"Poista valinnat"}
          </Button>
        </div>
      ) : null}
    </>
  )
}