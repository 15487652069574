import React, { useContext, useEffect, useState } from "react";
import { TextField } from "@mui/material"
import { EnergyCalculatorContext, SetContext } from "../Misc/Context";
import "./ParametersTab.css";
import { TCalcProps } from "dippa-shared";
import { COLORS } from "src/Misc/consts";

export const ParametersTab = () => {
  const { energyCalcResponse } = useContext(EnergyCalculatorContext);
  const { setEnergyCalcProps } = useContext(SetContext);
  const [tempEnergyCalcProps, setTempEnergyCalcProps] = useState<TCalcProps>();

  useEffect(() => {
    if (!energyCalcResponse?.calcProps || tempEnergyCalcProps) return;
    setTempEnergyCalcProps(energyCalcResponse.calcProps)
  }, [energyCalcResponse?.calcProps])

  if (!tempEnergyCalcProps) return <div className="parameters-tab" style={{ backgroundColor: COLORS.musta_black_combination }} />

  return (
    <div className="parameters-tab" style={{ backgroundColor: COLORS.musta_black_combination }}>
      <div style={{
        display: 'flex',
        flexDirection: "row",
        alignContent: "flex-start",
        flexWrap: 'wrap',
        gap: 15
      }}>
        {Object.entries(tempEnergyCalcProps).sort((a, b) => a[0].localeCompare(b[0])).map(([prop, value], index) => {
          if ((typeof value !== "number" && typeof value !== "string") || prop === "kayttotarkoitusluokka" || prop === "lammitysmuoto") return null; // TODO: Lämpöpumput, ikkunat yms
          return (
            <div key={prop} style={{ flex: 1 }}>
              <TextField
                key={index}
                required
                fullWidth
                id={prop}
                label={prop}
                name={prop}
                value={value}
                // @ts-ignore
                onChange={(e) => {
                  const val = e.target.value;
                  const valParsed = parseFloat(val);
                  // @ts-ignore
                  setTempEnergyCalcProps(prev => ({ ...prev, ...{ [prop]: val } }))
                  if (!isNaN(valParsed) && val.substring(val.length - 1) !== ".") {
                    setEnergyCalcProps(prev => {
                      if (!prev) {
                        // @ts-ignore
                        return { ...tempEnergyCalcProps, ...{ [prop]: valParsed } }
                      }

                      return { ...prev, ...{ [prop]: valParsed } }
                    })
                  }
                }}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}