import { interpolateColor } from "./commonFunctions"

export const COLORS = {
  "eluokat": {
    // "A": "#83c9f0",
    // 'B': "#35b98b",
    // 'C': "#7fc200",
    // 'D': "#ed6890",
    // 'E': "#ffeb44", Keltainen

    'A': '#648fff',
    'B': '#8771ea',
    'C': '#b352be',
    'D': '#dc267f',
    'E': '#f2554b',
    'F': '#fd8223',
    'G': '#ffeb44',
    "N/A": "#405060"


    // IBM
    // 'A': '#648fff',
    // 'B': '#8771ea',
    // 'C': '#b352be',
    // 'D': '#dc267f',
    // 'E': '#f2554b',
    // 'F': '#fd8223',
    // 'G': '#ffb000',
    // "N/A": "#484848"
  },
  "grays": {
    "chart_grid": interpolateColor("#ffffff", "#0f374f", 0.85),
    "chart_ticks": interpolateColor("#ffffff", "#0f374f", 0.4),
    "chart_scatter_points": interpolateColor("#ffffff", "#0f374f", 0.4),
  },
  "musta_black_combination": interpolateColor("#0f374f", "#000510", 0.3),
  "sitowise": {
    "puisto": "#7fc200",
    "graniitti": "#ed6890",
    "kajo": "#83c9f0",
    "satama": "#003956",
    "kupari": "#35b98b",
    "niitty": "#ffeb44",
    "musta": "#0f374f"
  }
} as const


export const SERVER_URL = process.env.REACT_APP_ENV === "development" ? "http://localhost:3001" : "https://api.energiakartta.fi"