import React, { useContext, useEffect, useRef } from "react";
import { Button, Checkbox, FormControlLabel, Slider } from "@mui/material";

import { TBuildingFilters, TBuildingProperties, formatRluok, formatLammonlahde, formatLammonjako, TRluok, formatRakennusaine, formatBuildPropKey } from "dippa-shared";
import { FiltersContext, MainContext, RefContext, SetContext } from "../Misc/Context";
import { COLORS } from "../Misc/consts";
import { NestedCheckbox } from "./NestedCheckbox";
import { LAMMONJAKO_CHECKBOXES, LAMMONLAHDE_CHECKBOXES, PROPS_AVAILABLE_CHECKBOXES, RAKENNUSAINE_CHECKBOXES, RAKENNUSLUOKITUS_CHECKBOXES } from "./checkboxVals";
import "./Filters.css";
import { DataDrivenPropertyValueSpecification } from "mapbox-gl";
import { CheckboxTitle } from "./CheckboxTitle";


const addRangeFilter = (
  filters: TBuildingFilters["rangeFilters"],
  key: keyof TBuildingProperties,
  newFilters: Array<DataDrivenPropertyValueSpecification<number>>
) => {
  const filter = filters?.[key];
  if (!filter) return;
  const { min, max } = filter;
  if (!min && !max) return;
  const arr: DataDrivenPropertyValueSpecification<number> = [
    "all",
    // When range filter is applied, all unknown values are filtered so that values within the range remain
    ["!=", ["get", key], null],
  ]
  if (min) {
    arr.push(
      [">=", ["get", key], min]
    )
  }
  if (max) {
    arr.push(
      ["<=", ["get", key], max]
    )
  }

  if (arr.length > 1) {
    newFilters.push(arr)
  }
}


const addValueFilter = (
  filters: TBuildingFilters["valueFilters"],
  key: keyof TBuildingProperties,
  newFilters: Array<DataDrivenPropertyValueSpecification<number>>
) => {
  const filter = filters?.[key];
  if (!filter) return;
  const arr: DataDrivenPropertyValueSpecification<number> = ["all"];
  for (const value of Array.from(filter)) {
    arr.push(["!=", ["get", key], value])
  }
  if (arr.length > 1) {
    newFilters.push(arr)
  }
}


const getTicks = (
  min: { value: number, label: string },
  max: { value: number, label: string },
  step: number
) => {
  const arr = []
  for (let i = min.value; i <= max.value; i += step) {
    if (i === min.value) {
      arr.push({ value: i, label: min.label })
    }
    else if (i === max.value) {
      arr.push({ value: i, label: max.label })
    }
    else {
      arr.push({ value: i, label: String(i) })
    }
  }

  return arr
}


const FilterCheckbox = ({
  filters,
  value,
  label,
  accessKey,
  setFilters,
  horizontal,
  color
}: {
  filters: TBuildingFilters,
  value: string | number,
  label?: string,
  accessKey: keyof TBuildingProperties,
  setFilters: React.Dispatch<React.SetStateAction<TBuildingFilters>>,
  horizontal?: boolean,
  color?: string
}) => {
  const { valueFilters } = filters;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!valueFilters?.[accessKey]?.has(value)}
          onChange={(_, val: boolean) => {
            const newSet = valueFilters[accessKey] || new Set()
            if (val) {
              newSet.delete(value)
            }
            else {
              newSet.add(value)
            }
            setFilters(prev => (
              {
                ...prev,
                ...{
                  valueFilters: {
                    ...prev.valueFilters,
                    [accessKey]: newSet
                  }
                }
              }
            ))
          }}
        />
      }
      label={label ? label : value}
      style={{
        color: color ? color : undefined,
        flexDirection: horizontal ? "column" : "row"
      }}
    />
  )
}


const FilterSlider = ({
  filters,
  min,
  max,
  tickInterval,
  step,
  accessKey,
  setFilters
}: {
  filters: TBuildingFilters,
  min: { value: number, label: string },
  max: { value: number, label: string },
  tickInterval: number,
  step: number,
  accessKey: keyof TBuildingProperties,
  setFilters: React.Dispatch<React.SetStateAction<TBuildingFilters>>
}) => {
  const { rangeFilters } = filters;
  const ticks = React.useMemo(() => getTicks(
    min,
    max,
    tickInterval
  ), [])
  const firstVal = rangeFilters[accessKey]?.min;
  const secondVal = rangeFilters[accessKey]?.max;


  const onChange = React.useCallback((event: Event, [low, high]: [number, number], activeThumb: number) => {
    setFilters(prev => (
      {
        ...prev,
        ...{
          rangeFilters: {
            ...prev.rangeFilters,
            [accessKey]: {
              min: low === min.value ? null : low,
              max: high === max.value ? null : high
            },
          }

        }
      }
    ))
  }, [])


  const valueLabelFormat = React.useCallback((label: number) => {
    if (label === min.value) {
      return min.label
    }
    if (label === max.value) {
      return max.label
    }
    return label
  }, [])


  return (
    <div className="filter-slider">
      <Slider
        //getAriaLabel={() => 'Temperature range'}
        value={[
          firstVal ? firstVal : min.value,
          secondVal ? secondVal : max.value
        ]}
        min={min.value}
        max={max.value}
        step={step}
        marks={ticks}
        //getAriaValueText={valuetext}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormat}
        // @ts-ignore
        onChange={onChange}
      />
    </div>
  )
}


const ACTION_KEYS = [
  "ikkunoidenUusim",
  "ulkoOvienUusim",
  "ylapohjanLisalammonerist",
  "ulkoseinienLisalammonerist",
  "valaistuksenUusim",
  "aurinkopaneelienAsent",
  "poistoilmalampopumpunAsent",
  "maalampopumpunAsent",
  "puhaltimienUusim",
  "vakiopaineventAsent",
  "kaukolampoonSiirt"
] as const


const ActionsSelector = ({
  filters,
  setFilters
}: {
  filters: TBuildingFilters,
  setFilters: React.Dispatch<React.SetStateAction<TBuildingFilters>>
}) => {
  const { valueFilters } = filters;
  return (
    <div className="filter-selector">
      <CheckboxTitle
        title={"Keskeiset suositukset"}
      />

      <div style={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        marginTop: "14px",
        marginBottom: "14px"
      }}>
        {(ACTION_KEYS).map((accessKey, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                checked={!!valueFilters?.[accessKey]?.has(2)}
                sx={{ paddingRight: 0.75 }}
                onChange={(_, val: boolean) => {
                  if (!val) {
                    setFilters(prev => {
                      const { [accessKey]: _, ...rest } = prev.valueFilters;
                      return (
                        {
                          ...prev,
                          ...{
                            valueFilters: rest
                          }
                        }
                      )
                    })
                  }
                  else {
                    setFilters(prev => (
                      {
                        ...prev,
                        ...{
                          valueFilters: {
                            ...prev.valueFilters,
                            [accessKey]: new Set([2])
                          }
                        }
                      }
                    ))
                  }
                }}
              />
            }
            label={formatBuildPropKey(accessKey)}
          />
        ))}
      </div>
    </div>
  )
}


export const Filters = () => {
  const { setBuildingLayerFilters, setFilters } = useContext(SetContext);
  const { filtersRef } = useContext(RefContext);
  const { filters } = useContext(FiltersContext);
  const timeoutThrottle = useRef<NodeJS.Timeout | null>(null);


  useEffect(() => {
    // A ref is used so that most recent data is available in timeout
    filtersRef.current = filters;
    if (timeoutThrottle.current !== null) {
      // Execution is pending
      return;
    }

    timeoutThrottle.current = setTimeout(() => {
      const newFilters: Array<DataDrivenPropertyValueSpecification<number>> = [];
      for (const key of Object.keys(filtersRef.current.rangeFilters)) {
        addRangeFilter(filtersRef.current.rangeFilters, key as keyof TBuildingProperties, newFilters);
      }
      for (const key of Object.keys(filtersRef.current.valueFilters)) {
        addValueFilter(filtersRef.current.valueFilters, key as keyof TBuildingProperties, newFilters);
      }
      setBuildingLayerFilters(newFilters);
      timeoutThrottle.current = null;
    }, 1000 / 15) // 15 fps
  }, [filters])


  const setValueFilter = React.useCallback((
    newSet: Set<string | number | null>,
    accessKey: keyof TBuildingProperties
  ) => {
    setFilters(prev => (
      {
        ...prev,
        ...{
          valueFilters: {
            ...prev.valueFilters,
            [accessKey]: newSet
          }
        }
      }
    ))
  }, [])


  const valueFilters = React.useMemo(() => (
    <>
      <div>
        <CheckboxTitle
          title={"Energialuokka (2018)"}
          selectAll={() => {
            setFilters(prev => {
              const { eluokka, ...rest } = prev.valueFilters;
              return {
                ...prev,
                ...{
                  valueFilters: rest
                }
              }
            })
          }}
          deselectAll={() => {
            setFilters(prev => ({
              ...prev,
              ...{
                valueFilters: {
                  ...prev.valueFilters,
                  eluokka: new Set(["A", "B", "C", "D", "E", "F", "G", "N/A"])
                }
              }
            }))
          }}
        />
        <div style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          marginTop: 13,
          marginLeft: 2,
          marginRight: 2
        }}>
          {(["A", "B", "C", "D", "E", "F", "G", "N/A"] as const).map((value, index: number) => (
            <FilterCheckbox
              key={index}
              filters={filters}
              value={value}
              accessKey={"eluokka"}
              setFilters={setFilters}
              horizontal={true}
              color={COLORS.eluokat[value]}
            />
          ))}
        </div>
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckbox
          title={"Energialaskuri saatavilla"}
          nestedItems={PROPS_AVAILABLE_CHECKBOXES}
          uncheckedItems={filters.valueFilters.accuratePropsAvailable}
          labelFormatter={(id) => {
            switch (id) {
              case 1:
                return "Kyllä"
              case 2:
                return "Ei"
            }
          }}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "accuratePropsAvailable")
          }}
          displayTitleButtons={false}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckbox
          title={"Rakennusluokitus"}
          nestedItems={RAKENNUSLUOKITUS_CHECKBOXES}
          uncheckedItems={filters.valueFilters.rluok}
          labelFormatter={(id) => formatRluok(id as TRluok)}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "rluok")
          }}
          showId={true}
          padFirstLevel={true}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <ActionsSelector
        filters={filters}
        setFilters={setFilters}
      />

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckbox
          title={"Lämmönlähde"}
          nestedItems={LAMMONLAHDE_CHECKBOXES}
          uncheckedItems={filters.valueFilters.lammonlahde}
          labelFormatter={formatLammonlahde}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "lammonlahde")
          }}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckbox
          title={"Lämmönjako"}
          nestedItems={LAMMONJAKO_CHECKBOXES}
          uncheckedItems={filters.valueFilters.lammonjako}
          labelFormatter={formatLammonjako}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "lammonjako")
          }}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>

      <div className="filter-selector">
        <NestedCheckbox
          title={"Rakennusaine"}
          nestedItems={RAKENNUSAINE_CHECKBOXES}
          uncheckedItems={filters.valueFilters.rakaine}
          labelFormatter={formatRakennusaine}
          onChecksChange={(newSet: Set<string | number | null>) => {
            setValueFilter(newSet, "rakaine")
          }}
        />
      </div>

      <div className="horizontal-divider-wrapper">
        <div className="horizontal-divider" />
      </div>
    </>
  ), [filters.valueFilters])


  // NOTE: Filters scale automatically.
  // Add a slider or checkbox component for given building geojson property.
  // They are then added to the filters object which is processed automatically
  return (
    <>
      {valueFilters}

      <div className="filter-container">
        <p>
          {"Valmistumisvuosi"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"vvuosi"}
          min={{ value: 1860, label: `≤${1860}` }}
          max={{ value: 2025, label: `≥${2025}` }}
          tickInterval={40}
          step={1}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"E-luku (kWh/m²/vuosi)"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"eluku"}
          min={{ value: 0, label: `0` }}
          max={{ value: 600, label: `≥600` }}
          tickInterval={100}
          step={5}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"Kokonaisala (m²)"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"korala"}
          min={{ value: 0, label: "0" }}
          max={{ value: 20000, label: `≥${20000}` }}
          tickInterval={5000}
          step={100}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"Kerrosten lukumäärä"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"kerrlkm"}
          min={{ value: 0, label: "0" }}
          max={{ value: 16, label: `≥${16}` }}
          tickInterval={2}
          step={1}
          setFilters={setFilters}
        />
      </div>

      <div className="filter-container">
        <p>
          {"Tilavuus (m³)"}
        </p>
        <FilterSlider
          filters={filters}
          accessKey={"tilavuus"}
          min={{ value: 0, label: "0" }}
          max={{ value: 60000, label: `≥${60000}` }}
          tickInterval={15000}
          step={200}
          setFilters={setFilters}
        />
      </div>
    </>
  )
}
