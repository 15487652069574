import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { forwardRef, useImperativeHandle } from 'react';
import { useControl } from 'react-map-gl';

import type { ControlPosition } from 'react-map-gl';
import { MapContextValue } from 'react-map-gl/dist/esm/components/map';
import { TSelectedPolygon } from '../Misc/Context';

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onCreate: (evt: { features: TSelectedPolygon[] }) => void;
  onUpdate: (evt: { features: TSelectedPolygon[]; action: string }) => void;
};

const DrawControl = forwardRef(function DrawControl(props: DrawControlProps, ref) {
  // @ts-ignore
  const controlRef = useControl<MapboxDraw>(
    () => new MapboxDraw(props),
    // | "draw.create"
    // | "draw.delete"
    // | "draw.update"
    // | "draw.render"
    // | "draw.combine"
    // | "draw.uncombine"
    // | "draw.modechange"
    // | "draw.actionable"
    // | "draw.selectionchange"
    ({ map }: MapContextValue) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
    },
    ({ map }: MapContextValue) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
    },
    {
      position: props.position
    }
  );

  useImperativeHandle(ref, () => controlRef, [controlRef]);

  return null;
})

DrawControl.defaultProps = {
  onCreate: () => { },
  onUpdate: () => { }
};

export default DrawControl;