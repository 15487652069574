import React from "react";
import { COLORS } from "./consts"

export const InfoArr = ({ arr }: { arr: Array<{ title: string, value: string | number | boolean }> }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      {arr.map(({ title, value }, index) => {
        // @ts-ignore
        const color = value && typeof value === "string" ? COLORS.eluokat[value] : undefined
        return (
          <div key={index}>
            <p>
              {title}
            </p>
            <h2 style={{ color }}>
              {value}
            </h2>
          </div>
        )
      }
      )}
    </div>
  )
}