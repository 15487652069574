import React, { useState } from 'react';
import { Button, Divider, Menu, MenuItem } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { TSetState } from '../Misc/Context';
import { MUI_GRAY } from '../Misc/muiThemes';
import { TDropdownSelection } from 'dippa-shared';
import { interpolateColor } from 'src/Misc/commonFunctions';
import { COLORS } from 'src/Misc/consts';



export const CustomDropdown = (
  {
    items,
    selectedItem,
    itemSetter,
    valFormatter,
    prefix
  }: {
    items: ReadonlyArray<{ title: string, accessKey: string, vals: ReadonlyArray<string> }>,
    selectedItem: string,
    itemSetter: TSetState<TDropdownSelection>,
    valFormatter: (value: string) => string,
    prefix?: string
  }
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onItemClick = React.useCallback((item: string, accessKey: string) => {
    handleClose();
    // @ts-ignore
    itemSetter({ val: item, accessKey: accessKey });
  }, [])

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        startIcon={<ChevronLeft sx={{ color: "#ffffff", fontSize: 25 }} />}
      >
        {`${prefix ? prefix : ""} ${valFormatter(selectedItem)}`}
      </Button>

      <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        component={"ul"}
        sx={{
          transform: 'translateX(-10px)',
        }}
      >
        {items.map((itemObj, index1) => {
          const { title, accessKey, vals: itemArr } = itemObj;
          return (
            <li key={index1}>
              {index1 ? (
                <Divider
                  sx={{ my: 0.5, backgroundColor: interpolateColor(COLORS.sitowise.musta, "#ffffff", 0.3) }}
                  variant={"middle"}
                />
              ) : null}
              {title ? (
                <p style={{
                  paddingLeft: "16px",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  color: MUI_GRAY,
                  fontSize: "13.5px"
                }}>
                  {title}
                </p>
              ) : null}
              {itemArr.map((item, index2) => (
                <MenuItem
                  key={index2}
                  onClick={() => onItemClick(item, accessKey)}
                  disableRipple
                >
                  {valFormatter(item)}
                </MenuItem>
              ))}
            </li>
          )
        })}
      </Menu>
    </>
  )
}
