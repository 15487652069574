import React, { useContext, useEffect, useRef, useState } from 'react';
import { GeoJSONFeature } from 'mapbox-gl';
import Mapbox, { Layer, NavigationControl, Source } from 'react-map-gl';
import type { MapEvent, MapMouseEvent, MapRef, MapTouchEvent, ViewStateChangeEvent } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import DrawControl from './DrawControl';
import { TBuildingPolygonGeojson, TBuildingPointGeojson, TMunicipalityAreasGeojson, TPostalAreasGeojson, TViewMode, TPointGeometry } from 'dippa-shared';
import { MainContext, RefContext, SelectedBuildingContext, SetContext, TSelectedPolygon } from '../Misc/Context';
import { DRAW_CONTROL_STYLES, getBuilding3dLayer, getBuildingLayer, getMunicipalityAreasLayer, getPostalAreasLayer, getSelectedBuildingLayer } from './layers';
import { fetchBuildings } from './fetchBuildings';
import { SERVER_URL } from '../Misc/consts';
import { axiosFetch, displayAxiosError } from '../Misc/commonFunctions';
import './Map.css';
import { SearchBox } from './SearchBox';


const INITIAL_ZOOM = 12;
export const BUILDINGS_3D_ZOOM_BOUNDARY = 14;
export const BUILDINGS_ZOOM_BOUNDARY = 11.85;
export const MUNICIPALITIES_ZOOM_BOUNDARY = 9.99;
const INITIAL_VIEW_STATE = {
  latitude: 60.18,
  longitude: 24.94,
  zoom: INITIAL_ZOOM
} as const;


export type TLoadedRegions = {
  [key: string]: {
    pointFeatures: TBuildingPointGeojson["features"],
    polygonFeatures: TBuildingPolygonGeojson["features"],
    rejectCount: number
  }
}


export const Map = ({ mapboxToken }: { mapboxToken: string }) => {
  const {
    buildingLayerFilters,
    viewMode,
    leftPanelCollapsed
  } = useContext(MainContext);
  const { selectedBuilding } = useContext(SelectedBuildingContext)
  const { drawControlRef } = useContext(RefContext);
  const {
    setEnergyCalculatorOpen,
    setSelectedPolygons,
    setViewMode,
    setSelectedBuilding,
    setLoadingStatus,
    setTooltip
  } = useContext(SetContext);
  const mapRef = useRef<MapRef | null>(null);

  const [municipalityAreasGeojson, setMunicipalityAreasGeojson] = useState<TMunicipalityAreasGeojson>();
  // const [selectedPolygonGeojson, setSelectedPolygonGeojson] = useState<TMunicipalityAreasGeojson | TPostalAreasGeojson>();
  const [postalAreasGeojson, setPostalAreasGeojson] = useState<TPostalAreasGeojson>();
  const [buildingsPointGeojson, setBuildingsPointGeojson] = useState<TBuildingPointGeojson>();
  const [buildingsPolygonGeojson, setBuildingsPolygonGeojson] = useState<TBuildingPolygonGeojson>();
  //const buildingFetchTimeout = useRef<NodeJS.Timeout>();
  const mouseClickSpecs = useRef<{ lat: number, lng: number, bearing: number, pitch: number }>();
  const prevRoundBBox = useRef({ minLon: 0, minLat: 0, maxLon: 0, maxLat: 0 });
  const prevZoomLevel = useRef(INITIAL_ZOOM);
  const loadedRegions = useRef<TLoadedRegions>({});
  const initialFetch = useRef(true);
  const pendingBuildingIdAutoSelect = useRef("");
  const boundChangeTimeout = useRef<NodeJS.Timeout>();
  const isMouseDown = useRef(false);


  const clearPolygons = () => {
    setSelectedPolygons({});
    drawControlRef.current?.deleteAll();
    drawControlRef.current?.changeMode("simple_select");
  }


  const changeViewMode = (mode: TViewMode) => {
    setSelectedBuilding(null);
    setEnergyCalculatorOpen(false);
    clearPolygons();
    if (mode !== "buildings") {
      setTooltip(undefined);
    }
    // else {
    //   // @ts-expect-error asdf
    //   setBuildingsPointGeojson();
    //   // @ts-expect-error asdf
    //   setBuildingsPolygonGeojson();
    // }
    setViewMode(mode)
  }


  const onMoveStart = React.useCallback(() => {
    if (viewMode === "buildings") {
      setLoadingStatus("loading");
      setTooltip(undefined);
    }
  }, [viewMode]);


  const onBoundChangeTimeout = React.useCallback(() => {
    if (isMouseDown.current) {
      // Map is being dragged. onMoveEnd was emitted incorrectly.
      // Another attempt is done recursively to cover an edge case where map
      // movement is stopped by clicking or holding mouse but not moving it.
      boundChangeTimeout.current = setTimeout(onBoundChangeTimeout, 50);
      return;
    }
    if (mapRef.current?.isMoving()) return; // Map is currently being zoomed

    const sc = mapRef.current?.getBounds();
    if (!sc) return;
    const minLon = Math.floor(sc._sw.lng * 10) / 10;
    const minLat = Math.floor(sc._sw.lat * 20) / 20;
    const maxLon = Math.ceil(sc._ne.lng * 10) / 10;
    const maxLat = Math.ceil(sc._ne.lat * 20) / 20;
    if (
      minLon === prevRoundBBox.current.minLon &&
      minLat === prevRoundBBox.current.minLat &&
      maxLon === prevRoundBBox.current.maxLon &&
      maxLat === prevRoundBBox.current.maxLat
    ) {
      setLoadingStatus("")
      return;
    }

    prevRoundBBox.current.minLon = minLon;
    prevRoundBBox.current.minLat = minLat;
    prevRoundBBox.current.maxLon = maxLon;
    prevRoundBBox.current.maxLat = maxLat;
    fetchAndSetBuildingsGeojson(minLon, minLat, maxLon, maxLat)
  }, []);


  const onBoundChange = React.useCallback((e?: ViewStateChangeEvent | MapEvent) => {
    clearTimeout(boundChangeTimeout.current);
    if (viewMode !== "buildings") return;
    if (e?.type === "load") {
      onBoundChangeTimeout();
      return;
    }

    // NOTE: Mapbox has a bug where onMoveEnd is emitted when dragging begins.
    // Bound change is prevented with a timeout that checks if mouse is pressed down
    boundChangeTimeout.current = setTimeout(onBoundChangeTimeout, 30);
  }, [viewMode])


  const onZoom = React.useCallback((e: ViewStateChangeEvent) => {
    const zoom = e?.viewState?.zoom;
    if (!zoom) return;

    if (zoom >= BUILDINGS_ZOOM_BOUNDARY
      && prevZoomLevel.current < BUILDINGS_ZOOM_BOUNDARY) {
      changeViewMode("buildings")
    }
    else if (zoom < BUILDINGS_ZOOM_BOUNDARY &&
      zoom >= MUNICIPALITIES_ZOOM_BOUNDARY &&
      (prevZoomLevel.current >= BUILDINGS_ZOOM_BOUNDARY ||
        prevZoomLevel.current < MUNICIPALITIES_ZOOM_BOUNDARY
      )) {
      setLoadingStatus("")
      changeViewMode("postalAreas")
    }
    else if (zoom < MUNICIPALITIES_ZOOM_BOUNDARY &&
      prevZoomLevel.current >= MUNICIPALITIES_ZOOM_BOUNDARY) {
      setLoadingStatus("")
      changeViewMode("municipalityAreas")
    }

    // const frac = (zoom - 12.5) / (15 - 12.5);
    // const pitch = Math.min(Math.max(0 * (1 - frac) + 70 * frac, 0), 70);
    // if (mapRef.current && mapRef.current.getPitch() > pitch) {
    //   mapRef.current.easeTo({
    //     zoom: zoom + 1,
    //     pitch: pitch,
    //     bearing: 80,
    //     duration: 500
    //   })
    // }
    prevZoomLevel.current = zoom;
  }, [])


  const onMouseOut = React.useCallback(() => {
    if (!mapRef.current) return
    mapRef.current.getCanvas().style.cursor = '';
    setTooltip(undefined);
  }, [])


  // TODO: Proper event type fails build compilation
  const onMouseMove = React.useCallback((e: any) => {
    if (!mapRef.current) return;
    if (drawControlRef.current && drawControlRef.current.getMode() === "draw_polygon") return;

    const feature = getViewModeFeature(e.features)
    if (!feature) {
      mapRef.current.getCanvas().style.cursor = '';
      setTooltip(undefined);
      return
    };

    if (viewMode === "buildings") {
      // @ts-expect-error
      setTooltip(feature)
    }
    else {
      setTooltip(undefined);
    }

    mapRef.current.getCanvas().style.cursor = 'pointer';
  }, [viewMode]);


  const onMouseDown = React.useCallback((e: MapMouseEvent | MapTouchEvent) => {
    if (!mapRef.current) return;
    const { lng, lat } = mapRef.current.getCenter();
    mouseClickSpecs.current = {
      lng: lng,
      lat: lat,
      bearing: mapRef.current.getBearing(),
      pitch: mapRef.current.getPitch()
    }
  }, []);


  // TODO: Proper event type fails build compilation
  const onMouseUp = React.useCallback((e: any) => {
    if (!mapRef.current) return;
    if (drawControlRef.current && drawControlRef.current.getMode() === "draw_polygon") return;

    const { lat, lng } = mapRef.current.getCenter()
    if (mouseClickSpecs.current?.lat !== lat
      || mouseClickSpecs.current?.lng !== lng
      || mouseClickSpecs.current?.bearing !== mapRef.current.getBearing()
      || mouseClickSpecs.current?.pitch !== mapRef.current.getPitch()
    ) {
      // Map was moved when mouse was down
      return
    }

    const feature = parseFeature(e.features)
    if (!feature) {
      // Empty area was clicked and map wasn't moved
      setSelectedBuilding(null);
      setEnergyCalculatorOpen(false);
      if (viewMode !== "buildings") {
        clearPolygons();
      }
      return
    }

    if (viewMode === "buildings") {
      // @ts-ignore
      setSelectedBuilding(feature);
      //clearPolygons();
      return
    }

    const coordinates = feature.geometry?.coordinates;
    if (!coordinates) return;

    drawControlRef.current?.add({
      // @ts-ignore
      geometry: feature.geometry,
      id: "postal-area-selection",
      properties: feature.properties,
      type: "Feature"
    })
    setSelectedPolygons({
      "postal-area-selection": {
        // @ts-ignore
        geometry: feature.geometry,
        id: "postal-area-selection",
        properties: feature.properties,
        type: "Feature"
      }
    });
  }, [viewMode, municipalityAreasGeojson, postalAreasGeojson, buildingsPointGeojson, buildingsPolygonGeojson])


  const getViewModeFeature = (features: Array<GeoJSONFeature> | undefined) => {
    if (!features) return;
    switch (viewMode) {
      case "postalAreas":
        for (const feature of features) {
          if (feature.layer?.source === "geojson-postal-areas") {
            return feature;
          }
        }
        return;
      case "municipalityAreas":
        for (const feature of features) {
          if (feature.layer?.source === "geojson-municipality-areas") {
            return feature;
          }
        }
        return;
      case "buildings":
        for (const feature of features) {
          if (mapRef.current!.getZoom() > BUILDINGS_3D_ZOOM_BOUNDARY) {
            if (feature.layer?.source === "geojson-3d-buildings") {
              return feature;
            }
          }
          if (feature.layer?.source === "geojson-buildings") {
            return feature;
          }
        }
    }
  }


  const parseFeature = (features: Array<GeoJSONFeature> | undefined) => {
    const feature = getViewModeFeature(features)
    if (!feature) return;
    // NOTE: Mapbox can sometimes split polygons into smaller ones
    // or lose coordinate accuracy.
    // Original feature has to be queried from geojsons
    switch (viewMode) {
      case "buildings":
        if (!feature?.properties?.rtunnus || !buildingsPointGeojson) return;
        return buildingsPointGeojson.features.find(
          // @ts-ignore
          f => f.properties.rtunnus === feature.properties.rtunnus
        )
      case "postalAreas":
        if (!feature?.properties?.postalCode || !postalAreasGeojson) return
        return postalAreasGeojson.features.find(
          // @ts-ignore
          f => f.properties.postalCode === feature.properties.postalCode
        );
      case "municipalityAreas":
        if (!feature?.properties?.finName || !municipalityAreasGeojson) return
        return municipalityAreasGeojson.features.find(
          // @ts-ignore
          f => f.properties.finName === feature.properties.finName
        );
    }
  }


  const onBuildingSearchSelect = React.useCallback((buildingId: string, pointGeojson: TPointGeometry) => {
    if (!mapRef.current) return;
    mapRef.current.jumpTo({ center: pointGeojson.coordinates, zoom: 15.5, pitch: 40, bearing: 0 });
    // mapRef.current.flyTo({ center: pointGeojson.coordinates, zoom: 16, pitch: 45, speed: 1.5, curve: 1, bearing: 0 })
    pendingBuildingIdAutoSelect.current = buildingId;
    if (buildingsPointGeojson) {
      findAndSelectBuilding(buildingsPointGeojson);
    }
  }, [buildingsPointGeojson])


  const building3dLayer = React.useMemo(() => (
    getBuilding3dLayer(buildingLayerFilters, selectedBuilding)
  ), [buildingLayerFilters, selectedBuilding])


  const buildingLayer = React.useMemo(() => (
    getBuildingLayer(buildingLayerFilters, viewMode)
  ), [buildingLayerFilters, viewMode])


  const selectedBuildingLayer = React.useMemo(() => (
    getSelectedBuildingLayer(buildingLayerFilters, viewMode)
  ), [buildingLayerFilters, viewMode])


  const postalAreasLayer = React.useMemo(() => (
    getPostalAreasLayer(viewMode)
  ), [viewMode])


  const municipalityAreasLayer = React.useMemo(() => (
    getMunicipalityAreasLayer(viewMode)
  ), [viewMode])


  const onPolygonUpdate = React.useCallback((e: { features: TSelectedPolygon[] }, action?: string) => {
    // Is called when polygon drawing is finished or modifying is finished
    setSelectedPolygons(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);


  const drawControlMemo = React.useMemo(() => (
    <DrawControl
      ref={drawControlRef}
      displayControlsDefault={false}
      onCreate={onPolygonUpdate}
      onUpdate={onPolygonUpdate}
      styles={DRAW_CONTROL_STYLES}
    />
  ), [])


  const findAndSelectBuilding = (geojson: TBuildingPointGeojson) => {
    const feature = geojson.features.find(
      // @ts-ignore
      f => pendingBuildingIdAutoSelect.current === f.properties.rtunnus
    )
    if (!feature) return;
    pendingBuildingIdAutoSelect.current = "";
    setSelectedBuilding(feature);
  }


  const fetchAndSetBuildingsGeojson = React.useCallback(async (
    minLon: number,
    minLat: number,
    maxLon: number,
    maxLat: number
  ) => {
    try {
      // if (worker.current) {
      //   worker.current.terminate();
      //   worker.current = null;
      // }
      setLoadingStatus("loading");
      const { gjsonPoints, gjsonPolygons } = await fetchBuildings({ minLon, minLat, maxLon, maxLat, loadedRegions });
      if (initialFetch.current) {
        // Postal and municipality areas are fetched after initial building fetch to make loading experience more smooth
        fetchAndSetPostalAreasGeojson();
        fetchAndSetMunicipalityAreasGeojson();
        initialFetch.current = false;
      }
      setBuildingsPointGeojson(gjsonPoints);
      setBuildingsPolygonGeojson(gjsonPolygons);
      setTimeout(() => {
        setLoadingStatus("");
      }, 600)

      if (pendingBuildingIdAutoSelect.current) {
        findAndSelectBuilding(gjsonPoints);
      }
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }

    //clearTimeout(buildingFetchTimeout.current);
    // buildingFetchTimeout.current = setTimeout(async () => {

    // }, buildingFetchTimeout.current ? 400 : 0)
  }, []);


  const fetchAndSetPostalAreasGeojson = async () => {
    try {
      const { data } = await axiosFetch(`${SERVER_URL}/postal-areas.geojson`)
      setPostalAreasGeojson(data);
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }
  }


  const fetchAndSetMunicipalityAreasGeojson = async () => {
    try {
      const { data } = await axiosFetch(`${SERVER_URL}/municipality-areas.geojson`)
      setMunicipalityAreasGeojson(data);
    }
    catch (e) {
      displayAxiosError(e, setLoadingStatus);
    }
  }


  useEffect(() => {
    setTimeout(() => {
      mapRef.current?.resize();
    }, 400)
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setSelectedBuilding(null);
        setEnergyCalculatorOpen(false);
        clearPolygons();
      }
    };
    const handleMouseDown = () => {
      isMouseDown.current = true;
      setTooltip(undefined);
    };
    const handleMouseUp = () => { isMouseDown.current = false };

    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('keydown', handleKeyPress);
      // worker.current?.terminate();
      // worker.current = null;
    };
  }, [])


  useEffect(() => {
    mapRef.current?.resize();
    if (viewMode === "buildings") {
      onBoundChange();
    }
  }, [leftPanelCollapsed, viewMode])



  const interactiveLayerIds = React.useMemo(() => (
    [buildingLayer.id!, building3dLayer.id!, postalAreasLayer[0].id!, municipalityAreasLayer[0].id!]
  ), [buildingLayer, building3dLayer, postalAreasLayer, municipalityAreasLayer])


  return (
    <Mapbox
      ref={mapRef}
      mapboxAccessToken={mapboxToken}
      initialViewState={INITIAL_VIEW_STATE}
      pitchWithRotate={true}
      dragRotate={true}
      maxPitch={60}
      mapStyle={"mapbox://styles/ottoro/clxx3h6m1010301qr0kz18hig"}
      interactiveLayerIds={interactiveLayerIds}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseOut}
      onTouchStart={onMouseDown}
      onTouchEnd={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onZoom={onZoom}
      //onMove={onBoundChange}
      onMoveStart={onMoveStart}
      onMoveEnd={onBoundChange}
      onLoad={onBoundChange}
    // terrain={{
    //   source: "mapbox-dem",
    //   exaggeration: 1.6
    // }}
    // antialias={true}
    >
      {postalAreasGeojson ? (
        <Source
          type="geojson"
          id='geojson-postal-areas'
          data={postalAreasGeojson}
          cluster={false}
          buffer={64}
        >
          {postalAreasLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {municipalityAreasGeojson ? (
        <Source
          type="geojson"
          id='geojson-municipality-areas'
          // @ts-ignore
          data={municipalityAreasGeojson}
          cluster={false}
          buffer={64}
        >
          {municipalityAreasLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {buildingsPolygonGeojson ? (
        <Source
          type="geojson"
          id='geojson-3d-buildings'
          data={buildingsPolygonGeojson}
          cluster={false}
        >
          <Layer {...building3dLayer} />
        </Source>
      ) : null}

      {buildingsPointGeojson ? (
        <Source
          type="geojson"
          id='geojson-buildings'
          data={buildingsPointGeojson}
          cluster={false}
          buffer={64}
        >
          <Layer {...buildingLayer} />
        </Source>
      ) : null}

      {selectedBuilding ? (
        <Source
          type="geojson"
          id='geojson-selected-building'
          data={selectedBuilding}
          cluster={false}
        >
          {selectedBuildingLayer.map((l, index) => (
            <Layer key={index} {...l} />
          ))}
        </Source>
      ) : null}

      {/* <Source
        id="mapbox-dem"
        type="raster-dem"
        url="mapbox://mapbox.terrain-rgb"
        tileSize={128}
        maxzoom={14}
      />
      <Layer
        id="terrain"
        type="hillshade"
        source="mapbox-dem"
        paint={{
          'hillshade-exaggeration': 0.25
        }}
      /> */}


      {drawControlMemo}
      {/* <NavigationControl
        position="bottom-right"
      /> */}

      <SearchBox
        onBuildingSearchSelect={onBuildingSearchSelect}
      />

      <img
        src="sitowise_logo_valkoinen.png"
        alt="Sitowise logo"
        className='sitowise-logo'
      />
    </Mapbox>
  );
};
