import React, { useContext, useState } from "react";
import { visuallyHidden } from '@mui/utils';

import {
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox
} from "@mui/material"
import { TEnergySavingActions } from "dippa-shared";
import { EnergyCalculatorContext, SetContext } from "../Misc/Context";



const descendingComparator = <T extends { [key: string]: string | number | null | boolean }>(a: T, b: T, orderBy: keyof T) => {
  const aVal = a[orderBy];
  const bVal = b[orderBy];
  if (aVal === null) return 1;
  if (bVal === null) return -1;
  if (aVal > bVal) return -1;
  if (aVal < bVal) return 1;
  return 0;
}


const ascendingComparator = <T extends { [key: string]: string | number | null | boolean }>(a: T, b: T, orderBy: keyof T) => {
  const aVal = a[orderBy];
  const bVal = b[orderBy];
  if (aVal === null) return 1;
  if (bVal === null) return -1;
  if (aVal < bVal) return -1;
  if (aVal > bVal) return 1;
  return 0;
}


type Order = 'asc' | 'desc';


function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: string | number | null | boolean },
  b: { [key in Key]: string | number | null | boolean },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => ascendingComparator(a, b, orderBy);
}

type HeadCell = {
  id: string;
  label: string;
  numeric: boolean;
}

const columns: Array<HeadCell> = [
  {
    id: 'id',
    label: 'Toimenpide',
    numeric: false
  },
  {
    id: 'ostoenergiaChange',
    label: 'Vaikutus\nostoenergiaan (%)',
    numeric: true
  },
  {
    id: 'elukuChange',
    label: 'Vaikutus\nE-lukuun (%)',
    numeric: true
  },
  {
    id: 'cost',
    label: 'Kustannus (€)',
    numeric: true
  },
  {
    id: 'paybackTime',
    label: 'Takaisinmaksuaika (a)',
    numeric: true
  }
];


type EnhancedTableProps = {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}


const EnhancedTableHead = ({ order, orderBy, numSelected, rowCount, onRequestSort }: EnhancedTableProps) => {
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'Select all actions',
            }}
          /> */}
        </TableCell>
        {columns.map((column, index) => (
          <TableCell
            key={column.id}
            align={column.numeric ? 'right' : 'left'}
            padding={'none'}
            sortDirection={orderBy === column.id ? order : false}
            sx={{ paddingRight: index === columns.length - 1 ? "12px" : undefined }}
          >
            <TableSortLabel
              active={orderBy === column.id}
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
              sx={{
                display: 'flex',
                justifyContent: column.numeric ? 'end' : 'start',
                alignItems: 'center',
                whiteSpace: 'pre-wrap', // Enables line breaks
                textAlign: 'right'
              }}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}


export const ActionsTable = () => {
  const { energyCalcResponse, selectedActions } = useContext(EnergyCalculatorContext);
  const { setSelectedActions } = useContext(SetContext);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('ostoenergiaChange');
  const rows = React.useMemo(() => energyCalcResponse?.actionsTable ?? [], [energyCalcResponse?.actionsTable]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelected = rows.map((n) => n.id);
  //     setSelectedActions(newSelected);
  //     return;
  //   }
  //   setSelectedActions([]);
  // };

  const handleClick = (event: React.MouseEvent<unknown>, id: TEnergySavingActions) => {
    const selectedIndex = selectedActions.indexOf(id);
    let newSelected: Array<TEnergySavingActions> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedActions, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedActions.slice(1));
    } else if (selectedIndex === selectedActions.length - 1) {
      newSelected = newSelected.concat(selectedActions.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedActions.slice(0, selectedIndex),
        selectedActions.slice(selectedIndex + 1),
      );
    }
    setSelectedActions(newSelected);
  };

  const isSelected = (id: TEnergySavingActions) => selectedActions.indexOf(id) !== -1;

  const visibleRows = React.useMemo(() => (
    [...rows].sort(getComparator(order, orderBy))
  ), [order, orderBy, rows]);

  return (
    <TableContainer sx={{ height: 358, WebkitUserSelect: "none", msUserSelect: "none", userSelect: "none" }}>
      <Table
        aria-labelledby="tableTitle"
        size={"medium"}
      >
        <EnhancedTableHead
          numSelected={selectedActions.length}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow
                onClick={(event) => handleClick(event, row.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                key={row.id}
                style={{
                  pointerEvents: row.disabled ? 'none' : 'auto',
                  opacity: row.disabled ? 0.3 : 1,
                }}
                selected={isItemSelected}
              >
                <TableCell padding="checkbox" >
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="none"
                  align={"left"}
                >
                  {row.id}
                </TableCell>

                <TableCell
                  padding="none"
                  align={"right"}
                >
                  {row.ostoenergiaChange.toFixed(1)}
                </TableCell>
                <TableCell
                  padding="none"
                  align={"right"}
                >
                  {row.elukuChange.toFixed(1)}
                </TableCell>

                <TableCell
                  padding="none"
                  align={"right"}
                >
                  {row.cost ? row.cost.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ") : ""}
                </TableCell>
                <TableCell
                  padding="none"
                  align={"right"}
                  sx={{ paddingRight: "12px" }}
                >
                  {row.paybackTime ? row.paybackTime.toFixed(0) : ""}
                </TableCell>
                {/* <TableCell
                      padding="none"
                      align={"right"}
                    >
                      {row.paybackTime.toFixed(1)}
                    </TableCell> */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}