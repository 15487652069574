import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  List,
  ListItem
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import "./NestedCheckbox.css"
import { CheckboxTitle } from './CheckboxTitle';


type NestedItem = {
  id: string | number | null;
  label?: string;
  children?: ReadonlyArray<NestedItem>;
}


type ExtractIds<T> = T extends { id: infer U; children?: ReadonlyArray<infer V> }
  ? U | ExtractIds<V>
  : never;


export const NestedCheckbox = <T extends NestedItem>({
  title,
  nestedItems,
  uncheckedItems = new Set(),
  labelFormatter,
  onChecksChange,
  showId,
  padFirstLevel,
  displayTitleButtons = true
}: {
  title: string,
  nestedItems: ReadonlyArray<T>,
  uncheckedItems: Set<string | number | null> | undefined,
  labelFormatter: (id: ExtractIds<T>) => string,
  onChecksChange: (changed: Set<string | number | null>) => void,
  showId?: boolean,
  padFirstLevel?: boolean,
  displayTitleButtons?: boolean
}) => {
  //const [uncheckedItems, setUncheckedItems] = useState<{ [key: string]: boolean }>({});
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({ title: true });


  const handleCheckboxChange = (item: NestedItem, isChecked: boolean) => {
    const updateCheckedItems = (
      item: NestedItem,
      checked: boolean,
      newSet: Set<string | number | null>
    ) => {
      const { id } = item;
      const idFormatted = id && id !== "null" ? id : null
      if (checked) {
        newSet.delete(idFormatted)
      }
      else {
        newSet.add(idFormatted)
      }
      if (item.children) {
        item.children.forEach((child) => {
          updateCheckedItems(child, checked, newSet);
        });
      }
    };

    const newSet: Set<string | number | null> = uncheckedItems ? uncheckedItems : new Set()
    updateCheckedItems(item, isChecked, newSet);
    onChecksChange(newSet);
  };


  const handleToggle = (item: NestedItem) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [String(item.id)]: !prevOpenItems[String(item.id)],
    }));
  };


  const renderNestedItems = (items: ReadonlyArray<NestedItem>, level: number): JSX.Element[] => {
    return items.map((item) => {
      // @ts-ignore 
      const label = (showId && level && item.id !== null ? `${item.id} ` : "") + (item?.label ? item.label : labelFormatter(item.id))

      return (
        <div key={item.id} className="main-div">
          <ListItem sx={{ padding: 0, paddingTop: 0.7, paddingBottom: 0.7 }}>
            {item.children ? (
              <IconButton onClick={() => handleToggle(item)} sx={{ width: 28, padding: 0 }}>
                {openItems[String(item.id)] ? (
                  <ExpandLess sx={{ color: "#b8b8b8", fontSize: 25 }} />
                ) : (
                  <ExpandMore sx={{ color: "#b8b8b8", fontSize: 25 }} />
                )}
              </IconButton>
            ) : (
              <div style={{ width: level === 1 && !padFirstLevel ? 6 : 28 }} />
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={!uncheckedItems || !uncheckedItems.has(item.id)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCheckboxChange(item, e.target.checked)}
                  sx={{ paddingRight: 0.75 }}
                />
              }
              sx={{ flex: 1 }}
              label={label}
            />
          </ListItem>
          {item.children && (
            <Collapse in={openItems[String(item.id)]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderNestedItems(item.children, level + 1)}
              </List>
            </Collapse>
          )}
        </div>
      )
    });
  };


  return (
    <>
      <CheckboxTitle
        title={title}
        selectAll={displayTitleButtons ? () => {
          handleCheckboxChange({ id: "title", label: title, children: nestedItems }, true)
        } : undefined}
        deselectAll={displayTitleButtons ? () => {
          handleCheckboxChange({ id: "title", label: title, children: nestedItems }, false)
        } : undefined}
      />
      <List sx={{ marginLeft: -3 }}>
        {/* {renderNestedItems([
        { id: "title", label: title, children: nestedItems }
      ], 0)} */}
        {renderNestedItems(nestedItems, 1)}
      </List>
    </>
  );
};