import { getRegionKeysInsideBounds, TBuildingPolygonGeojson, TBuildingPointGeojson, TBuildingProperties } from "dippa-shared";
import { axiosFetch } from "../Misc/commonFunctions";
import { SERVER_URL } from "../Misc/consts";
import { TLoadedRegions } from "./Map";


const fetchRegion = async (bbox: string): Promise<TLoadedRegions["region"]> => {
  const res = await axiosFetch(`${SERVER_URL}/building-region.json?bbox=${bbox}`)
  if (res.status === 205) {
    // Empty area
    return { pointFeatures: [], polygonFeatures: [], rejectCount: 0 };
  }
  const begin = performance.now();

  const json = res.data;
  const pointFeatures: TBuildingPointGeojson["features"] = [];
  const polygonFeatures: TBuildingPolygonGeojson["features"] = [];
  const keys = Object.keys(json);
  const keysFiltered = keys.filter(v => (v !== "geometry" && v !== "lat" && v !== "lon"));
  const keysFilteredLength = keysFiltered.length;
  const len = json[keys[0]].length;
  for (let i = 0; i < len; i++) {
    const properties = {} as TBuildingProperties;
    for (let j = 0; j < keysFilteredLength; j++) {
      const key = keysFiltered[j];
      const val = json[key][i];
      if (val !== null) {
        // @ts-expect-error
        properties[key] = val;
      }
    }

    // const properties = keysFiltered.reduce((prev, key) => {
    //   // @ts-ignore
    //   if (json[key][i] !== null) {
    //     // @ts-ignore
    //     prev[key] = json[key][i];
    //   }
    //   return prev
    // }, {} as TBuildingProperties);


    // @ts-ignore
    properties.hasPolygon = !!json.geometry[i];
    pointFeatures.push({
      "type": "Feature",
      "properties": properties,
      "geometry": { type: "Point", coordinates: [json.lon[i], json.lat[i]] }
    })
    if (json.geometry[i]) {
      polygonFeatures.push({
        "type": "Feature",
        "properties": properties,
        "geometry": json.geometry[i]
      });
    }
  }

  console.log(bbox, Math.round((performance.now() - begin) * 100) / 100)

  return { pointFeatures, polygonFeatures, rejectCount: 0 };
}


const getRegion = async (
  bbox: string,
  gjsonPoints: TBuildingPointGeojson,
  gjsonPolygons: TBuildingPolygonGeojson,
  loadedRegions: React.MutableRefObject<TLoadedRegions>
) => {
  if (loadedRegions.current[bbox]?.pointFeatures && loadedRegions.current[bbox]?.polygonFeatures) {
    // Concat is slightly faster than spread operator
    gjsonPoints.features = gjsonPoints.features.concat(loadedRegions.current[bbox].pointFeatures);
    gjsonPolygons.features = gjsonPolygons.features.concat(loadedRegions.current[bbox].polygonFeatures);
    return;
  }
  const { pointFeatures, polygonFeatures, rejectCount } = await fetchRegion(bbox);
  loadedRegions.current[bbox] = { pointFeatures, polygonFeatures, rejectCount };
  gjsonPoints.features = gjsonPoints.features.concat(pointFeatures);
  gjsonPolygons.features = gjsonPolygons.features.concat(polygonFeatures);
}


const limitBounds = (
  minLat: number,
  minLon: number,
  maxLat: number,
  maxLon: number
) => {
  while (true) {
    const size = Math.round((maxLon - minLon) * 10) * Math.round((maxLat - minLat) * 20)
    if (size < 21) {
      break;
    }

    //maxLon = Math.round((maxLon - 0.1) * 10) / 10;
    maxLat = Math.round((maxLat - 0.05) * 20) / 20;
    //maxLat = Math.round((maxLat - 0.05) * 20) / 20;

    // if (maxLon - minLon > 0.21) {
    //   minLon = Math.round((minLon + 0.1) * 10) / 10;
    // }

    // if (maxLat - minLat > 0.11) {
    //   minLat = Math.round((minLat + 0.05) * 20) / 20;
    // }
  }

  return { minLat, minLon, maxLat, maxLon }
}


export const fetchBuildings = async ({
  minLat,
  minLon,
  maxLat,
  maxLon,
  loadedRegions
}: {
  minLat: number,
  minLon: number,
  maxLat: number,
  maxLon: number,
  loadedRegions: React.MutableRefObject<TLoadedRegions>
}) => {
  const gjsonPoints: TBuildingPointGeojson = {
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    "features": []
  };
  const gjsonPolygons: TBuildingPolygonGeojson = {
    "type": "FeatureCollection",
    "crs": {
      "type": "name",
      "properties": {
        "name": "urn:ogc:def:crs:OGC:1.3:CRS84"
      }
    },
    "features": []
  };
  const promises = [];
  const bboxes = getRegionKeysInsideBounds(limitBounds(minLat, minLon, maxLat, maxLon));
  for (const bbox of Object.keys(loadedRegions.current)) {
    // Regions that are not going to be displayed anymore are removed
    if (bboxes.includes(bbox)) continue;

    loadedRegions.current[bbox].rejectCount++;
    if (loadedRegions.current[bbox].rejectCount > 12) {
      delete loadedRegions.current[bbox];
    }
  }

  for (const bbox of bboxes) {
    promises.push(getRegion(bbox, gjsonPoints, gjsonPolygons, loadedRegions))
  }
  await Promise.all(promises)

  return { gjsonPoints, gjsonPolygons };
}
