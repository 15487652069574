import React, { useEffect, useRef, useState } from "react";
import { axiosFetch } from "../Misc/commonFunctions";
import { SERVER_URL } from "../Misc/consts";
import "./SearchBox.css";
import { MenuItem, TextField, ThemeProvider } from "@mui/material";
import { TApi, TPointGeometry } from "dippa-shared";
import { MUI_THEME_SEARCH_BOX } from "src/Misc/muiThemes";


export const SearchBox = ({
  onBuildingSearchSelect
}: {
  onBuildingSearchSelect: (buildingId: string, pointGeojson: TPointGeometry) => void
}) => {
  const [query, setQuery] = useState('');
  const [activeIndex, setActiveIndex] = useState(-1);
  const [results, setResults] = useState<TApi["building-search.json"]["response"]>([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [error, setError] = useState("");
  const selectedRef = useRef<HTMLLIElement | null>(null);
  const mainRef = useRef<HTMLDivElement | null>(null);
  const timeout = useRef<NodeJS.Timeout>();


  const handleKeyDown = React.useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex((prevIndex) => {
        const gotoFirst = prevIndex === results.length - 1;
        if (gotoFirst) {
          selectedRef.current?.parentElement?.firstElementChild?.scrollIntoView({ block: "nearest" });
          return 0;
        }

        selectedRef.current?.nextElementSibling?.scrollIntoView({ block: "nearest" });
        return prevIndex + 1;
      });

    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex((prevIndex) => {
        const gotoLast = prevIndex === 0;
        if (gotoLast) {
          selectedRef.current?.parentElement?.lastElementChild?.scrollIntoView({ block: "nearest" });
          return results.length - 1;
        }

        selectedRef.current?.previousElementSibling?.scrollIntoView({ block: "nearest" });
        return prevIndex - 1;
      });
    } else if (e.key === 'Enter' && activeIndex !== -1) {
      onItemSelect(results[activeIndex].buildingId, results[activeIndex].pointGeojson)
    }
  }, [results, activeIndex]);


  const debouncedSearch = React.useCallback((searchTerm: string) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      if (searchTerm.trim() === '') {
        setResults([]);
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axiosFetch(`${SERVER_URL}/building-search.json?address=${searchTerm}`);
        setResults(response.data);
      }
      catch (err) {
        setError('Virhe ladattaessa hakutuloksia');
      }

      setLoading(false);
    }, 200)
  }, []);


  const onItemSelect = (buildingId: string, pointGeojson: TPointGeometry) => {
    setResults([]);
    setLoading(false);
    setError("");
    setQuery("");
    setActiveIndex(-1);
    onBuildingSearchSelect(buildingId, pointGeojson)
  }

  const handleClickOutside = (e: MouseEvent) => {
    if (!mainRef.current) return;
    if (mainRef.current.contains(e.target as Node)) {
      setActive(true);
    }
    else {
      setActiveIndex(-1);
      setActive(false);
    }
  }


  useEffect(() => {
    debouncedSearch(query);
    return () => {
      clearTimeout(timeout.current);
    }
  }, [query]);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const handleChange = (e: any) => {
    setQuery(e.target.value);
    setError("");
  };

  return (
    <div
      ref={mainRef}
      onKeyDown={handleKeyDown}
      className="search-box"
    >
      <ThemeProvider theme={MUI_THEME_SEARCH_BOX}>
        <TextField
          fullWidth
          id="buildingSearch"
          //label="Hae "
          placeholder="Hae rakennusta katuosoitteella..."
          name="buildingSearch"
          value={query}
          onChange={handleChange}
        />
        {loading && <div className="loading">Ladataan...</div>}
        {error && <div className="error">{error}</div>}
        {(results.length > 0 && active) ? (
          <ul className="search-results">
            {results.map((
              { _id, buildingId, streetAddress, municipality, pointGeojson }: TApi["building-search.json"]["response"][0],
              index: number
            ) => (
              <MenuItem
                key={_id}
                ref={activeIndex === index ? selectedRef : null}
                selected={activeIndex === index}
                onClick={() => onItemSelect(buildingId, pointGeojson)}
                disableRipple
              >
                {`${streetAddress}, ${municipality}, ${buildingId}`}
              </MenuItem>
            ))}
          </ul>
        ) : null
        }
      </ThemeProvider>
    </div>
  );
}